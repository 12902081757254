import { useTheme } from '@material-ui/core'
import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { FlexGrowCol, FlexGrowRow } from 'sdk-xy-react'

import LifeHashAppBar from './AppBar'
import { Footer, InvertableThemeProvider } from './Components'
import { AboutPage, HomePage, PrivacyPage, SupportPage, TermsPage } from './Pages'
import { themeOptions } from './theme'

const AppBody: React.FC = () => {
  const theme = useTheme()
  return (
    <Router>
      <FlexGrowCol
        width="100vw"
        minHeight="100vh"
        justifyContent="flex-start"
        alignContent="stretch"
        bgcolor={theme.palette.background.default}
        color={theme.palette.text.primary}
      >
        <LifeHashAppBar />
        <FlexGrowRow>
          <Switch>
            <Route component={HomePage} exact path="/" />
            <Route component={SupportPage} exact path="/support" />
            <Route component={AboutPage} exact path="/about" />
            <Route component={TermsPage} exact path="/terms" />
            <Route component={PrivacyPage} exact path="/privacy" />
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </FlexGrowRow>
        <Footer />
      </FlexGrowCol>
    </Router>
  )
}

const AppThemeBody: React.FC = () => {
  return (
    <InvertableThemeProvider options={themeOptions}>
      <AppBody />
    </InvertableThemeProvider>
  )
}

const App: React.FC = () => {
  return (
    <InvertableThemeProvider options={themeOptions}>
      <Helmet defaultTitle="Lifehash.org" titleTemplate="%s | Lifehash.org" />
      <AppThemeBody />
    </InvertableThemeProvider>
  )
}

export default App
